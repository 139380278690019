const mobileBreakpoint = 820;
let isMobile = window.innerWidth <= mobileBreakpoint;
// const bookingAPI = 'https://cms-api.klarschiff.de/api';
const bookingAPI = 'https://api.ag-ems.de/api';
const bookingUrl = 'https://booking.ag-ems.de';
const excursionAPI = "https://cms-api.klarschiff.de/api/excursion?";

const routes = [
  { id: 0, name: 'Emden - Borkum', portFrom: 47, portTo: 66, craftType: 'VEHICLE_FERRY', duration: 130 },
  { id: 1, name: 'Borkum - Emden', portFrom: 66, portTo: 47, craftType: 'VEHICLE_FERRY', duration: 130 },
  { id: 2, name: 'Emden - Borkum', portFrom: 47, portTo: 66, craftType: 'CATAMARAN', duration: 60 },
  { id: 3, name: 'Borkum - Emden', portFrom: 66, portTo: 47, craftType: 'CATAMARAN', duration: 60 },
  { id: 4, name: 'Emden - Borkum', portFrom: 47, portTo: 66, craftType: 'PERSON_FERRY', duration: 130 },
  { id: 5, name: 'Borkum - Emden', portFrom: 66, portTo: 47, craftType: 'PERSON_FERRY', duration: 130 },
  { id: 6, name: 'Eemshaven - Borkum', portFrom: 2, portTo: 66, craftType: 'VEHICLE_FERRY', duration: 50 },
  { id: 7, name: 'Borkum - Eemshaven', portFrom: 66, portTo: 2, craftType: 'VEHICLE_FERRY', duration: 50 },
  { id: 8, name: 'Eemshaven - Borkum', portFrom: 2, portTo: 66, craftType: 'PERSON_FERRY', duration: 60 },
  { id: 9, name: 'Borkum - Eemshaven', portFrom: 66, portTo: 2, craftType: 'PERSON_FERRY', duration: 60 },
  { id: 10, name: 'Ditzum - Borkum', portFrom: 26, portTo: 66, craftType: 'CATAMARAN', duration: 90 },
  { id: 11, name: 'Borkum - Ditzum', portFrom: 66, portTo: 26, craftType: 'CATAMARAN', duration: 90 },
  { id: 12, name: 'Eemshaven - Borkum', portFrom: 2, portTo: 66, craftType: 'CATAMARAN', duration: 30 },
  { id: 13, name: 'Borkum - Eemshaven', portFrom: 66, portTo: 2, craftType: 'CATAMARAN', duration: 30 },
  { id: 14, name: 'Cuxhaven - Helgoland', portFrom: 89, portTo: 77, craftType: 'PERSON_FERRY', duration: 150 },
  { id: 15, name: 'Helgoland - Cuxhaven', portFrom: 77, portTo: 89, craftType: 'PERSON_FERRY', duration: 150 },
  { id: 16, name: 'Cuxhaven - Helgoland', portFrom: 89, portTo: 77, craftType: 'CATAMARAN', duration: 75 },
  { id: 17, name: 'Helgoland - Cuxhaven', portFrom: 77, portTo: 89, craftType: 'CATAMARAN', duration: 75 },
  { id: 18, name: 'Bremerhaven - Helgoland', portFrom: 120, portTo: 77, craftType: 'PERSON_FERRY', duration: 180 },
  { id: 19, name: 'Helgoland - Bremerhaven', portFrom: 77, portTo: 120, craftType: 'PERSON_FERRY', duration: 180 },
  { id: 19, name: 'Bremerhaven - Helgoland', portFrom: 120, portTo: 77, craftType: 'CATAMARAN', duration: 105 },
  { id: 20, name: 'Helgoland - Bremerhaven', portFrom: 77, portTo: 120, craftType: 'CATAMARAN', duration: 105 },
  { id: 21, name: 'Hooksiel - Helgoland', portFrom: 83, portTo: 77, craftType: 'PERSON_FERRY', duration: 120 },
  { id: 22, name: 'Helgoland - Hooksiel', portFrom: 77, portTo: 83, craftType: 'PERSON_FERRY', duration: 120 },
  { id: 23, name: 'Cuxhaven - Neuwerk', portFrom: 89, portTo: 53, craftType: 'PERSON_FERRY', duration: 120 },
  { id: 24, name: 'Neuwerk - Cuxhaven', portFrom: 53, portTo: 89, craftType: 'PERSON_FERRY', duration: 120 }
];

const r2PortId = {
  'EMD': 47,
  'BOR': 66,
  'EEM': 2,
  'DIT': 26
}

const bookingRoutes = [
  { id: 0, portFrom: 47, portTo: 66 },
  { id: 1, portFrom: 66, portTo: 47 },
  { id: 2, portFrom: 2, portTo: 66 },
  { id: 3, portFrom: 66, portTo: 2 },
  { id: 4, portFrom: 26, portTo: 66 },
  { id: 4, portFrom: 66, portTo: 26 },
  { id: 0, portFrom: 89, portTo: 77 },
  { id: 1, portFrom: 77, portTo: 89 },
  { id: 2, portFrom: 120, portTo: 77 },
  { id: 3, portFrom: 77, portTo: 120 },
  { id: 4, portFrom: 83, portTo: 77 },
  { id: 5, portFrom: 77, portTo: 83 },
  { id: 6, portFrom: 89, portTo: 53 },
  { id: 7, portFrom: 53, portTo: 89 }
];

const excursionRoutes = [
  // { id: 6, key: "wattnkonzert", name: "Watt'n Konzert" },
  { id: 7, key: "EMDHFG", name: "Große Hafenfahrt" },
  { id: 8, key: "EMDHFK", name: "Kleine Hafenfahrt" },
  // { id: 9, key: "ratsdelftkunsthalle", name: "Grachtenfahrt Ratsdelft - Kunsthalle" },
  // { id: 10, key: "kunsthalleratsdelft", name: "Grachtenfahrt Kunsthalle - Ratsdelft" },
  // { id: 11, key: "grachtenfahrt", name: "Grachtenfahrt" },
  // { id: 12, key: "hafenfahrtditzum", name: "Hafenfahrt Ditzum" },
  // { id: 13, key: "emdenditzum", name: "Tagesfahrt Emden-Ditzum", return: "ditzumemden" },
  // { id: 14, key: "greetsielborkum", name: "Tagesfahrt Greetsiel-Borkum", return: "borkumgreetsiel" }
];

const getExcursion = (key) => {
  return excursionRoutes.find(item => item.key === key)
}

const getDateInDays = (inputDate, days) => {
  let date = new Date(inputDate);
  date.setDate(date.getDate() + days);
  return date;
}

const getRouteId = (portFrom, portTo) => {
  const result = bookingRoutes.find(item => item.portFrom == portFrom && item.portTo == portTo);
  return !!result ? result.id : undefined;
}

window.addEventListener('load', function () {
  window.addEventListener('resize', () => {
    isMobile = window.innerWidth <= mobileBreakpoint;
  });

  const collapses = Array.prototype.slice.call(document.querySelectorAll('.js-collapse-trigger'));
  collapses.forEach(function (item) {
    item.addEventListener('click', toggleCollapse);
  });

  const mobileCollapses = Array.prototype.slice.call(
    document.querySelectorAll(".js-collapse-mobile")
  );
  mobileCollapses.forEach(function (item) {
    item.addEventListener("click", function (event) {
      if (isMobile) toggleCollapse(event, true);
    });
  });

  const searchTrigger = document.getElementById('searchTrigger');
  if (searchTrigger) {
    searchTrigger.addEventListener('click', toggleSearchPanel);
  }

  Array.prototype.slice.call(document.querySelectorAll('.input__clear')).forEach(function (item) {
    item.addEventListener('click', cleanInput);
  })

  Array.prototype.slice.call(document.querySelectorAll('.js-timeline-viewport')).forEach(initTimeline);

  const galleries = document.getElementsByClassName('js-gallery');
  if (galleries.length > 0) {
    initGallery(galleries[0]);
  }

  const lightboxGalleries = document.querySelectorAll(".lb-gallery");
  if (lightboxGalleries.length > 0) initLightboxGallery(lightboxGalleries);

  Array.prototype.slice.call(document.querySelectorAll('.js-tab')).forEach(function (item) {
    item.addEventListener('click', showTab);
  });

  const raitingNodes = document.getElementsByClassName('js-raiting');
  if (raitingNodes.length > 0) {
    initRaiting(raitingNodes[0]);
  }

  Array.prototype.slice.call(document.querySelectorAll('.js-lightbox')).forEach(item => {
    item.addEventListener('click', showLightbox);
  });

  counters();

  Array.prototype.slice.call(document.querySelectorAll('.js-overlay')).forEach(item => {
    showOverlay(item);
  });

  Array.prototype.slice.call(document.querySelectorAll('.js-slider')).forEach(item => {
    initSlider(item);
  });

  Array.prototype.slice.call(document.querySelectorAll('.js-mobile-slider')).forEach(item => {
    initSlider(item, true);
  });

  document.querySelectorAll('.js-r2widget')
    .forEach(node => initExcursionWidget(node));

  Array.prototype.slice.call(document.querySelectorAll('.js-schedule')).forEach(schedule => {
    initSchedule(schedule);

    const toBookLink = schedule.querySelector('.js-book-link');
    if (toBookLink) {
      toBookLink.addEventListener('click', (e) => {
        let today = new Date().toISOString();
        let from = 0, to = 1, fromDate = today, toDate = today;

        const ports = schedule.querySelectorAll('.js-route.tab-active');
        if (ports[0]) from = getRouteId(ports[0].dataset.from, ports[0].dataset.to) || 0;
        if (ports[1]) to = getRouteId(ports[1].dataset.from, ports[1].dataset.to) || 1;
        
        const dates = schedule.querySelectorAll('.js-section-date.flatpickr-input:not(.flatpickr-mobile)');
        if (dates[0]) fromDate = dates[0].value;
        if (dates[1]) toDate = dates[1].value;

        const params = `from=${from}&to=${to}&fromDate=${fromDate}&toDate=${toDate}`;
        const url = toBookLink.href.indexOf('from') > 0 ? toBookLink.dataset.href : toBookLink.href;
        toBookLink.href = url + (url.indexOf('?') > 0 ? '&' : '?') + params;
      })
    }
  });

  Array.prototype.slice.call(document.querySelectorAll('.mobile-crop-text')).forEach(item => {
    if (isMobile) cropText(item, 100);
  });

  Array.prototype.slice.call(document.querySelectorAll('.js-tour')).forEach(item => {
    initTour(item);
  });

  Array.prototype.slice.call(document.querySelectorAll('.js-vessel-positions')).forEach(item => {
    initVesselPositions(item);
  });

  slider();
});

function toggleSearchPanel() {
  const navigation = document.getElementById('mainNavigation');
  if (navigation.classList.contains('sub-active')) {
    navigation.classList.remove('sub-active');
  }
  if (navigation.classList.contains('search-active')) {
    navigation.classList.remove('search-active');
  } else {
    navigation.classList.add('search-active');
  }
}

function toggleCollapse(event, onlyMobile = false) {
  if (onlyMobile && !isMobile) return;
  if (event.target.parentElement.classList.contains('active')) {
    event.target.parentElement.classList.remove('active');
  } else {
    const currentActive = document.querySelector('.collapse.active');
    if (currentActive) currentActive.classList.remove('active');
    event.target.parentElement.classList.add('active');
  };
}

function cleanInput() {
  this.previousElementSibling.value = '';
}

function showTab() {
  if (!this.classList.contains('js-tab-link')) {
    const contentId = this.getAttribute('data-content');
    const contentNode = this.parentNode.nextElementSibling;
    if (contentId && contentNode) {
      this.parentNode.querySelector('.js-tab.active').classList.remove('active');
      contentNode.querySelector('.js-tab-content.active').classList.remove('active');
      document.getElementById(contentId).classList.add('active');
      this.classList.add('active');
    }
  }
}

function initGallery(galleryNode) {
  const items = galleryNode.querySelectorAll('li');

  const gallery = {
    itemHeight: isMobile ? 470 : 460, // 440 + 30 or 420 + 40,
    itemCount: items.length,
    activeItem: 0,
    startY: isMobile ? 0 : 150,
    translateY: isMobile ? 0 : 150
  };

  if (items.length > 1) {
    const itemImg = items[1].querySelector('img');
    if (itemImg) {
      itemImg.src = itemImg.dataset.src;
      if (itemImg.srcset) itemImg.dataset.srcset;
    }
  }
  if (items.length > 2) {
    if (!isMobile) items[items.length - 1].style.transform = `translateY(-${items.length * gallery.itemHeight}px)`;
    const itemImg = items[items.length - 1].querySelector('img');
    if (itemImg) {
      itemImg.src = itemImg.dataset.src;
      if (itemImg.srcset) itemImg.dataset.srcset;
    }
  }
  const viewport = galleryNode.querySelector('ul');

  // ToDO: refactoring

  galleryNode.querySelector('.js-gallery-top').addEventListener('click', () => {
    if (gallery.activeItem !== 0) {
      gallery.activeItem -= 1;
      items[gallery.activeItem + 1].classList.remove('active');
      items[gallery.activeItem].classList.add('active');
      viewport.style.transform = `translateY(${gallery.startY - gallery.activeItem * gallery.itemHeight}px)`;

      if (gallery.activeItem === 0) {
        items[items.length - 1].style.transform = `translateY(-${items.length * gallery.itemHeight}px)`;
      }

      if (gallery.activeItem === gallery.itemCount - 2) {
        items[0].style.transform = `translateY(0px)`;
      }
    } else {
      // to last
      gallery.activeItem = items.length - 1;
      items[0].classList.remove('active');
      items[gallery.activeItem].classList.add('active');
      viewport.style.transform = `translateY(${gallery.startY - gallery.activeItem * gallery.itemHeight}px)`;
      items[items.length - 1].style.transform = `translateY(0px)`;
      items[0].style.transform = `translateY(${items.length * gallery.itemHeight}px)`;

    }
  });

  galleryNode.querySelector('.js-gallery-bottom').addEventListener('click', () => {
    if (gallery.activeItem < gallery.itemCount - 1) {
      gallery.activeItem += 1;
      items[gallery.activeItem - 1].classList.remove('active');
      items[gallery.activeItem].classList.add('active');
      viewport.style.transform = `translateY(${gallery.startY - gallery.activeItem * gallery.itemHeight}px)`;

      if (gallery.activeItem === 1) {
        items[items.length - 1].style.transform = `translateY(0px)`;
      }

      if (gallery.activeItem === gallery.itemCount - 1) {
        items[0].style.transform = `translateY(${items.length * gallery.itemHeight}px)`;
      } else if (gallery.activeItem !== gallery.itemCount - 2) {
        // load next image
        const itemImg = items[gallery.activeItem + 1].querySelector('img');
        if (itemImg) itemImg.src = itemImg.dataset.src;
      }
    } else {
      // to first item
      gallery.activeItem = 0;
      items[gallery.itemCount - 1].classList.remove('active');
      items[gallery.activeItem].classList.add('active');
      viewport.style.transform = `translateY(${gallery.startY}px)`;
      items[items.length - 1].style.transform = `translateY(-${items.length * gallery.itemHeight}px)`;
      items[0].style.transform = `translateY(0px)`;
    }
  });
}

function initRaiting(raitingNode) {
  const itemWidth = (isMobile ? 300 : 400) + 30;
  const raitingList = raitingNode.querySelector('.raiting__list');
  let currentRate = 0;

  const triggers = document.getElementsByName('raitingTrigger');
  triggers.forEach(trigger => {
    trigger.addEventListener('change', e => {
      currentRate = +trigger.dataset.index;
      toCurrentRate();
    })
  })

  let touch = null;
  const itemCount = raitingList.childElementCount;
  raitingNode.addEventListener('touchmove', e => {
    if (touch === null) touch = e.touches['0'].clientX;
  });
  raitingNode.addEventListener('touchend', e => {
    if (touch) {
      if (touch > e.changedTouches['0'].clientX) {
        if (currentRate < itemCount - 1) {
          currentRate++;
        }
      } else {
        if (currentRate > 0) {
          currentRate--;
        }
      };
      toCurrentRate();
      raitingNode.querySelector(`input#item-${currentRate}`).checked = true;
    }
  });

  const toCurrentRate = () => {
    raitingList.style.transform = `translateX(-${itemWidth * currentRate}px)`;
  }
}

function showLightbox(event) {
  const lightboxWrapper = document.createElement('div');
  lightboxWrapper.classList.add('lightbox-backdrop');

  const closeLightbox = () => {
    document.body.removeChild(lightboxWrapper);
    document.body.classList.remove('no-overflow');
  }

  lightboxWrapper.addEventListener('click', event => {
    if (!event.target.closest('.lightbox')) {
      closeLightbox();
    }
  })

  const lightbox = document.createElement('div');
  lightbox.classList.add('lightbox');

  const closeButton = document.createElement('button');
  closeButton.classList.add('icon', 'icon_close');
  closeButton.onclick = closeLightbox;
  lightbox.appendChild(closeButton);

  const image = document.createElement('img');
  image.src = event.target.src;
  lightbox.appendChild(image);

  const title = document.createElement('p');
  title.innerText = event.target.alt;
  lightbox.appendChild(title);

  lightboxWrapper.appendChild(lightbox);
  document.body.insertBefore(lightboxWrapper, document.body.lastChild);
  // document.body.classList.add('no-overflow');
}

function initTimeline(timelineViewportNode) {
  const timelineNode = timelineViewportNode.querySelector('.js-timeline');
  let limit = +timelineViewportNode.dataset.limit;
  const moreButton = timelineViewportNode.querySelector('.js-timeline-more');
  const items = timelineNode.querySelectorAll('.timeline__item');
  if (limit > items.length) limit = items.length;
  setHeight();
  let showAll = false;

  window.addEventListener('resize', setHeight);
  window.addEventListener('orientationchange', setHeight);
  moreButton.addEventListener('click', showAllContent);

  function setHeight() {
    let leftHeight = 0;
    let rightHeight = 0;
    let viewportHeight = 0;

    items.forEach(function (item, index) {
      if (item.classList.contains('timeline__item_right')) {
        rightHeight += item.offsetHeight;
      } else {
        leftHeight += item.offsetHeight;
      }
    });
    if (window.innerWidth <= 820) {
      timelineNode.style.height = 'auto';
    } else {
      timelineNode.style.height = (rightHeight > leftHeight ? rightHeight : leftHeight) + 'px';
    }
    timelineViewportNode.style.height = showAll ? 'auto' : (items[limit - 1].offsetTop - timelineNode.offsetTop + items[limit - 1].offsetHeight) + 'px';
    timelineViewportNode.style.visibility = 'visible';
  }

  function showAllContent() {
    showAll = true;
    timelineNode.querySelectorAll('.timeline__item.section_hidden').forEach(function (item) {
      item.classList.remove('section_hidden');
    });
    moreButton.classList.add('hidden');
    timelineViewportNode.style.height = timelineNode.style.height;
  }
}

function counters() {
  let counters = [].slice.call(document.querySelectorAll('.js-counter'));
  let countersActive = false;

  const counterHandler = () => {
    if (!countersActive) countersActive = true;

    setTimeout(function () {
      counters.forEach(item => {
        if ((item.getBoundingClientRect().top <= window.innerHeight && item.getBoundingClientRect().bottom >= 0)
          && getComputedStyle(item).display !== 'none') {
          const num = +item.dataset.num;
          let count = 0;
          const counter = setInterval(timer, 2000 / num);

          function timer() {
            if (count === num) {
              clearInterval(counter);
            } else {
              count += 1;
            }
            item.innerText = count;
          }

          counters = counters.filter(count => count !== item);

          if (counters.length === 0) {
            document.removeEventListener('scroll', counterHandler);
            window.removeEventListener('resize', counterHandler);
            window.removeEventListener('orientationchange', counterHandler);
          }
        }
      });
      countersActive = false;
    }, 400)
  }
  counterHandler();
  document.addEventListener('scroll', counterHandler);
  window.addEventListener('resize', counterHandler);
  window.addEventListener('orientationchange', counterHandler);
}

function showOverlay(overlay) {
  const id = overlay.getAttribute('id');

  if (!document.cookie.includes(`${id}-VIEWED`)) {
    setTimeout(function () {
      overlay.classList.add('visible');
    }, 5000);
  }

  function setViewed() {
    const date = new Date();
    date.setTime(date.getTime() + ((30 * 24 * 60 * 60 * 1000) + 30));
    document.cookie = `${id}-VIEWED=1;expires=${date.toGMTString()};path=${window.location.pathname}`;
  }

  overlay.querySelector('.js-overlay-close').addEventListener('click', function () {
    setViewed();
    overlay.classList.remove('visible');
    overlay.remove();
  });

  overlay.addEventListener('click', setViewed);
}

function initSlider(sliderNode, onlyMobile = false) {
  const isInfinite = sliderNode.dataset.infinite === '1';
  const sliderList = sliderNode.querySelector('.slider__list');
  const sliderContent = isInfinite ? sliderNode.querySelector('.slider__content') : sliderList;
  let currentSlide = 0;

  const triggers = document.getElementsByName('sliderTrigger');
  triggers.forEach(trigger => {
    trigger.addEventListener('change', e => {
      currentSlide = +trigger.dataset.index;
      toCurrentSlide();
    })
  })

  let touch = null;
  const itemCount = sliderList.childElementCount;
  sliderNode.addEventListener('touchmove', e => {
    if (e.target.classList.contains('trigger__button')) return;
    if (touch === null) touch = e.touches['0'].clientX;
  });
  sliderNode.addEventListener('touchend', e => {
    if (touch && !e.target.classList.contains('trigger__button')) {
      if (touch > e.changedTouches['0'].clientX) {
        currentSlide < itemCount - 1 ? currentSlide++ : currentSlide = 0;
      } else {
        currentSlide > 0 ? currentSlide-- : currentSlide = itemCount - 1;
      };
      toCurrentSlide();
    }
  });

  const prevButton = sliderNode.getElementsByClassName('js-slider-prev')[0];
  const nextButton = sliderNode.getElementsByClassName('js-slider-next')[0];
  prevButton.addEventListener('click', e => {
    currentSlide > 0 ? currentSlide-- : currentSlide = itemCount - 1;
    toCurrentSlide(currentSlide === itemCount - 1);
  });
  nextButton.addEventListener('click', e => {
    currentSlide < itemCount - 1 ? currentSlide++ : currentSlide = 0;
    toCurrentSlide(currentSlide === 0);
  })

  const toCurrentSlide = (withoutAnimation) => {
    if (onlyMobile && !isMobile) return;
    if (isInfinite) {
      if (withoutAnimation) sliderContent.style.transitionProperty = 'none';
      else if (sliderContent.style.transitionProperty === 'none') sliderContent.style.transitionProperty = 'transform';
    };
    sliderContent.style.transform = `translateX(-${(isMobile ? 80 + 5 : 400 + 30) * currentSlide}${isMobile ? '%' : 'px'})`;
    sliderNode.querySelector(`input#item-${currentSlide}`).checked = true;
  }
}

function initExcursionWidget(eventcalendarNode) {
  const excursion = !!eventcalendarNode.dataset.experience ? getExcursion(eventcalendarNode.dataset.experience) : null;
  if (!excursion) return;

  let currentRequest;
  const widgetSubmit = eventcalendarNode.getElementsByClassName('js-r2widget-submit');
  const dataNode = eventcalendarNode.getElementsByClassName('js-eventcalendar')[0];
  const messageNote = eventcalendarNode.getElementsByClassName('js-eventcalendar-error');
  const bookLabel = eventcalendarNode.dataset.cta || 'Buchen';
  // ToDo: get lng en | de | nl from data attribute
  // const lng = eventcalendarNode.dataset.lng ?? 'de';
  const lng = eventcalendarNode.dataset.lng == '3' ? 'en' : eventcalendarNode.dataset.lng == '2' ? 'nl' : 'de';
  let preventGetTrips = false;
  const limitDays = 30;
  let period = parseInt(eventcalendarNode.dataset.daysInFuture);
  if (!period || period > limitDays) period = limitDays;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const adultsPicker = eventcalendarNode.getElementsByClassName('js-adults-picker')[0];
  const childrenPicker = eventcalendarNode.getElementsByClassName('js-children-picker')[0];

  const start = eventcalendarNode.dataset.start ? new Date(eventcalendarNode.dataset.start) : new Date();
  let defaultFromDate = start > new Date() ? start : new Date();
  defaultFromDate.setHours(0, 0, 0);
  let defaultToDate = getDateInDays(defaultFromDate, period);

  if (urlParams.has('from') && urlParams.has('to')) {
    defaultFromDate = new Date(convertDate(urlParams.get('from')));
    defaultToDate = new Date(convertDate(urlParams.get('to')));
  }

  const dateConfig = {
    altInput: true,
    altFormat: "d.m.Y",
    dateFormat: "Y-m-d",
    minDate: "today"
  };

  const dateFromNode = eventcalendarNode.getElementsByClassName('js-date-from')[0];
  let selectedDateFrom = defaultFromDate;
  const dateFromPrevBtn = eventcalendarNode.getElementsByClassName('js-date-from-prev')[0];
  const dateFromNextBtn = eventcalendarNode.getElementsByClassName('js-date-from-next')[0];
  const flatpickrFrom = flatpickr(dateFromNode, {
    ...dateConfig,
    defaultDate: defaultFromDate,
    onChange: datepickerFromChange
  });
  dateFromNode.parentElement.classList.remove('hidden');

  const dateToNode = eventcalendarNode.getElementsByClassName('js-date-to')[0];
  let selectedDateTo = defaultToDate;
  let maxDateTo = getDateInDays(defaultFromDate, period);
  const dateToPrevBtn = eventcalendarNode.getElementsByClassName('js-date-to-prev')[0];
  const dateToNextBtn = eventcalendarNode.getElementsByClassName('js-date-to-next')[0];
  const flatpickrTo = flatpickr(dateToNode, {
    ...dateConfig,
    defaultDate: defaultToDate,
    onChange: datepickerToChange,
    maxDate: maxDateTo
  });
  dateToNode.parentElement.classList.remove('hidden');

  if (widgetSubmit && widgetSubmit.length > 0) {
    preventGetTrips = true;
    widgetSubmit[0].addEventListener('click', () => {
      getTrips();
      preventGetTrips = false;
    });
  } else {
    getTrips();
  }

  function copyDate(date) {
    return new Date(date.getTime());
  }

  function setMinAndMaxToDate(_fromDate) {
    const toDate = flatpickrTo.selectedDates?.[0];
    if (toDate && toDate < _fromDate) {
      flatpickrTo.setDate(_fromDate, null, "d.m.Y");
      selectedDateTo = copyDate(_fromDate);
    }
    flatpickrTo.set('minDate', _fromDate);
    maxDateTo = getDateInDays(_fromDate, period);
    if (toDate && toDate > maxDateTo) {
      flatpickrTo.setDate(maxDateTo, null, "d.m.Y");
      selectedDateTo = copyDate(maxDateTo);
    }
    flatpickrTo.set('maxDate', maxDateTo);
  }

  function datepickerFromChange(selectedDates) {
    if (!selectedDates.length) return;
    selectedDateFrom = selectedDates[0];

    selectedDateTo = getDateInDays(selectedDates[0], period);
    setMinAndMaxToDate(selectedDates[0]);
    flatpickrTo.setDate(selectedDateTo, null, "d.m.Y");

    if (!preventGetTrips) getTrips();
  }

  function datepickerToChange(selectedDates) {
    if (!selectedDates.length) return;
    selectedDateTo = selectedDates[0];

    if (!preventGetTrips) getTrips();
  }

  dateFromPrevBtn.addEventListener('click', function () {
    if (isSameDay(new Date(), selectedDateFrom)) return;
    setSelectedDate(flatpickrFrom, selectedDateFrom, -1);
    setMinAndMaxToDate(selectedDateFrom);
  });

  dateFromNextBtn.addEventListener('click', function () {
    setSelectedDate(flatpickrFrom, selectedDateFrom, 1);
    setMinAndMaxToDate(selectedDateFrom);
    if (isSameDay(selectedDateFrom, selectedDateTo)) setSelectedDate(flatpickrTo, selectedDateTo, 1);
  });

  dateToPrevBtn.addEventListener('click', function () {
    if (isSameDay(selectedDateFrom, selectedDateTo)) return;
    setSelectedDate(flatpickrTo, selectedDateTo, -1);
  });

  dateToNextBtn.addEventListener('click', function () {
    if (isSameDay(selectedDateTo, maxDateTo)) return;
    setSelectedDate(flatpickrTo, selectedDateTo, 1);
  });

  function setSelectedDate(datepicker, value, step) {
    value.setDate(value.getDate() + step);
    datepicker.setDate(value, null, "d.m.Y");

    if (!preventGetTrips) getTrips();
  }

  function getTrips() {
    if (currentRequest) currentRequest.abort();
    dataNode.classList.add('loading');
    dataNode.innerHTML = '';
    if (messageNote.length > 0) messageNote[0].classList.add('hidden');
    const requestUrl = excursionAPI + `routeCode=${excursion.key}&fromDate=${getFormatedDate(selectedDateFrom)}&toDate=${getFormatedDate(selectedDateTo)}${excursion.return ? `&returnRouteCode=${excursion.return}` : ''}`;
    const request = new XMLHttpRequest();
    request.onreadystatechange = function () {
      if (request.readyState === XMLHttpRequest.DONE) {
        if (request.status == 200) {
          let trips = JSON.parse(request.response);
          if (trips.length > 0) {
            showTrips(trips);
          } else {
            if (messageNote.length > 0) messageNote[0].classList.remove('hidden');
          }
          dataNode.classList.remove('loading');
        } else {
          console.log('fehler aufgetretten', request)
        }
      }
    }
    currentRequest = request;
    request.open('GET', requestUrl);
    request.setRequestHeader('Content-Type', 'application/json');
    request.send();
  }

  function convertDate(date) {
    date = date.split('.');
    return new Date(date[1] + "/" + date[0] + "/" + date[2]);
  }

  function showTrips(trips) {
    const tripGroups = getTripGroups(trips);

    tripGroups.forEach(function (group) {
      const dayTripsNode = document.createElement('div');
      dayTripsNode.classList.add('trip-group');
      const dayDate = document.createElement('h4');
      const _date = new Date(group.date);
      dayDate.innerText = `${flatpickr.formatDate(_date, "l, d.m.Y", flatpickr.l10ns[lng])}`;
      const dayTrips = document.createElement('ul');
      group.trips.forEach(trip => {
        if (excursion.return && !trip.returnDeparture) return;
        showTripNode(trip, dayTrips);
      })
      dayTripsNode.appendChild(dayDate);
      dayTripsNode.appendChild(dayTrips);
      dataNode.appendChild(dayTripsNode);
    })

    const bookingLinks = dataNode.querySelectorAll('.js-trip-action');
    bookingLinks.forEach(item => {
      item.addEventListener('click', () => {
        if (adultsPicker && childrenPicker) item.href += `&adults=${adultsPicker.value}&children=${childrenPicker.value}`;
      })
    })
  }

  function showTripNode(trip, parrent) {
    const tripNode = document.createElement('li');
    tripNode.classList.add('trip');
    const returnParams = excursion.return && trip.returnDeparture ? `&withReturn=true&to=${excursion.return}&toDate=${trip.returnDeparture.departureDate}&toTime=${trip.returnDeparture.departureTime}` : '&withReturn=false';
    const bookLink = !!excursion ? `${bookingUrl}?excursion=true&from=${excursion.key}&fromTime=${trip.departureTime}&fromDate=${trip.departure}${returnParams}&lng=${lng}` : '';
    const isBookable = trip.bookable && trip.utilization.passengers < 100 && isLaterThanNow(trip.departure);

    tripNode.innerHTML = `
      <div class="trip__start">${trip.departureTime}</div>
      ${`<div class="trip__utilization utilization utilization__passengers ${trip.utilization.passengers === 100 ? 'utilization_danger' : (trip.utilization.passengers >= 80 ? 'utilization_warning' : '')}"><span style="width: ${trip.utilization.passengers}%;"></span></div>`}
      ${isBookable ? `<a href="${bookLink}" class="action action_xs action_blue js-trip-action">${bookLabel}</a>` : ''}
    `;
    parrent.appendChild(tripNode);
  }

  function getTripGroups(trips) {
    const groups = trips.reduce((groups, trip) => {
      const date = trip.departure.split('T')[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(trip);
      return groups;
    }, {});

    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        trips: groups[date]
      };
    });

    return groupArrays;
  }
}

function getFormatedDate(date) {
  const _date = typeof date !== 'object' ? new Date(date) : date;
  return `${_date.getFullYear()}-${_date.getMonth() + 1}-${_date.getDate()}`;

}

function isLaterThanNow(date) {
  const now = new Date();
  const _date = new Date(date);
  return _date > now;
}

function isLater(d1, d2) {
  return new Date(d1).getTime() > new Date(d2).getTime();
}

function diff_minutes(_dt2, _dt1) {
  const dt1 = new Date(_dt1);
  const dt2 = new Date(_dt2);
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
}

function getDuration(trip) {
  const tripTime = routes.filter(item => {
    const craftType = trip.craft.id === 'KATAMARAN' ? 'CATAMARAN' : trip.craft.type;
    return item.portFrom === (typeof trip.departurePort.id === 'string' ? r2PortId[trip.departurePort.id] : trip.departurePort.id)
      && item.portTo === (typeof trip.arrivalPort.id === 'string' ? r2PortId[trip.arrivalPort.id] : trip.arrivalPort.id)
      && item.craftType === craftType
  });
  if (tripTime.length > 0) return tripTime[0].duration;
  else return diff_minutes(trip.arrival, trip.departure);
}

function initSchedule(scheduleNode) {
  const url = scheduleNode.dataset.api || bookingAPI;
  const translations = {
    catamaran: scheduleNode.dataset.catamaran || 'Katamaran',
    ferry: scheduleNode.dataset.ferry || 'Fähre',
    triptime: scheduleNode.dataset.triptime || 'Fahrzeit',
    express: scheduleNode.dataset.express || 'Express',
  }

  const today = new Date().toISOString().split('T')[0];
  const startDate = scheduleNode.dataset.mindate || today;

  Array.prototype.slice.call(scheduleNode.getElementsByClassName('js-schedule-section')).forEach(function (section) {
    let currentRequest;
    const scheduleData = section.getElementsByClassName('js-schedule-data')[0];
    let selectedPortFrom;
    let selectedPortTo;
    const sectionStartDate = isLater(today, new Date(startDate)) ? today : startDate; 
    const sectionSelectedDate = (!!section.dataset.selectedDate && isLater(section.dataset.selectedDate, sectionStartDate)) ? section.dataset.selectedDate : sectionStartDate;
    let selectedDate = sectionSelectedDate;

    const noTripsMessage = section.getElementsByClassName('js-schedule-notrips')[0];

    const routeButtons = Array.prototype.slice.call(section.getElementsByClassName('js-route'));
    routeButtons.forEach(function (routeButton) {
      routeButton.addEventListener('click', function () {
        tabClickHandler(this);
      });
    });
    if (routeButtons.length > 0) tabClickHandler(routeButtons[0]);

    const datePrevBtn = section.getElementsByClassName('js-date-prev')[0];
    const dateNextBtn = section.getElementsByClassName('js-date-next')[0];

    const date = section.getElementsByClassName('js-section-date')[0];
    let dateInput = flatpickr(date, {
      altInput: true,
      altFormat: "D. d.m.Y",
      dateFormat: "Y-m-d",
      defaultDate: sectionSelectedDate,
      minDate: sectionStartDate,
    });
    date.parentElement.classList.remove('hidden');

    date.addEventListener('change', function () {
      onDateChange(this);
    });

    function onDateChange(dateNode) {
      selectedDate = new Date(dateNode.value);
      getTrips();
      datePrevBtn.disabled = isSameDay(new Date(selectedDate), sectionStartDate);
    }

    datePrevBtn.disabled = isSameDay(new Date(selectedDate), sectionStartDate);
    datePrevBtn.addEventListener('click', function () {
      const dateValue = new Date(selectedDate);
      dateValue.setDate(dateValue.getDate() - 1);
      dateInput.setDate(dateValue, onDateChange, "d.m.Y");
    });

    dateNextBtn.addEventListener('click', function () {
      const dateValue = new Date(selectedDate);
      dateValue.setDate(dateValue.getDate() + 1);
      dateInput.setDate(dateValue, onDateChange, "d.m.Y");
    });

    function tabClickHandler(trigger) {
      const activeClass = 'tab-active';
      const currentActive = section.getElementsByClassName(activeClass);
      if (currentActive.length > 0) currentActive[0].classList.remove(activeClass);
      trigger.classList.add(activeClass);
      selectedPortFrom = trigger.dataset.from;
      selectedPortTo = trigger.dataset.to;
      getTrips();
    }

    function getTrips() {
      if (currentRequest) currentRequest.abort();
      scheduleData.classList.add('loading');
      if (!noTripsMessage.classList.contains('hidden')) noTripsMessage.classList.add('hidden');
      scheduleData.innerHTML = '';
      const isNeuwerk = selectedPortFrom == "53" || selectedPortTo == "53";
      const type = url.indexOf('cassen-eils') !== -1 && !isNeuwerk ? 2 : 1;
      const requestUrl = url + `/trip?type=${type}&port=${selectedPortFrom}&toPort=${selectedPortTo}&fromDate=${new Date(selectedDate).toISOString()}`;
      const request = new XMLHttpRequest();
      request.onreadystatechange = function () {
        if (request.readyState === XMLHttpRequest.DONE) {
          if (request.status == 200) {
            let trips = JSON.parse(request.response);
            if (isSameDay(selectedDate, new Date())) {
              trips = trips.filter(function (item) {
                return new Date(item.departure).getDate() === new Date().getDate();
              })
            }
            if (trips.length > 0) {
              showTrips(scheduleData, trips, selectedPortFrom, selectedPortTo);
            } else {
              noTripsMessage.classList.remove('hidden');
            }
            scheduleData.classList.remove('loading');
          } else {
            if (request.status === 0) return;
          }
        }
      }
      currentRequest = request;
      request.open('GET', requestUrl);
      request.setRequestHeader('Content-Type', 'application/json');
      request.send();
    }
  });

  function showTrips(scheduleData, trips, portFrom, portTo) {
    const tripsNode = document.createElement('ul');

    trips.forEach(trip => {
      showTripNode(trip, tripsNode);
    })
    scheduleData.appendChild(tripsNode);
  }

  function showTripNode(trip, parrent) {
    const tripNode = document.createElement('li');
    const isCatamaran = trip.craft.type === 'CATAMARAN' || trip.craft.id === 'KATAMARAN';
    tripNode.classList.add('route');
    let utilizationHtml = `<div class="utilization utilization__passengers ${trip.utilization.passengers === 100 ? 'utilization_danger' : (trip.utilization.passengers >= 80 ? 'utilization_warning' : '')}"><span style="width: ${trip.utilization.passengers}%;"></span></div>`;
    if (trip.craft.type === 'VEHICLE_FERRY') utilizationHtml += `<div class="utilization utilization__vehicles ${trip.utilization.vehicles === 100 ? 'utilization_danger' : (trip.utilization.vehicles >= 80 ? 'utilization_warning' : '')}"><span style="width: ${trip.utilization.vehicles}%;"></span></div>`;
    tripNode.innerHTML = `
      ${isCatamaran ? `<div class="route__label">${translations.express}</div>` : ''}
      <div class="route__craft">
        <div class="craft__icon ${isCatamaran ? 'person' : 'vehicle'}"></div>
        <div class="craft__title">${isCatamaran ? translations.catamaran : translations.ferry}</div>
      </div>
      <div class="route__time">
        <div class="time__start">${getTime(trip.departure)}</div>
        <div class="time__trip">${translations.triptime}: ca. ${getDuration(trip)} min. <span class="color-warning">*</span></div>
      </div>
      <div class="route__utilization">${utilizationHtml}</div>
    `;
    parrent.appendChild(tripNode);
  }
}

function isSameDay(_d1, _d2) {
  const d1 = typeof _d1 !== 'object' ? new Date(_d1) : _d1;
  const d2 = typeof _d2 !== 'object' ? new Date(_d2) : _d2;
  return d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();
}

function getTime(_date) {
  const date = new Date(_date + '+01:00'); // add berlin timezone
  date.setTime(date.getTime() + (date.getTimezoneOffset() + 60) * 60 * 1000); // remove timezone offset
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
}

function initLightboxGallery(galleryNodes) {
  const backdrop = document.querySelector(".js-backdrop");
  const container = backdrop.querySelector(".js-image");
  const prevBtn = backdrop.querySelector(".js-prev");
  const nextBtn = backdrop.querySelector(".js-next");
  const description = document.getElementById("item-description");
  const meta = document.getElementById("item-meta");
  let image;
  let index;
  let activeItems;

  function cleanup() {
    backdrop.classList.remove("open");
    const gImg = container.querySelector("img");
    if (gImg) gImg.remove();
    description.innerText = "";
    meta.innerText = "";
    prevBtn.classList.remove("hidden");
    nextBtn.classList.remove("hidden");
  }

  // close functions
  backdrop.querySelector(".js-close").addEventListener("click", cleanup);
  backdrop.addEventListener("click", function (event) {
    if (event.target.nodeName !== "IMG" && event.target.nodeName !== "BUTTON")
      cleanup();
  });

  function getImage(slide) {
    let img = null;
    const placeholder = slide.querySelector("img");
    const imgSrc = placeholder.dataset.fullsrc;
    if (imgSrc) {
      img = document.createElement("img");
      img.src = imgSrc;
      img.alt = placeholder.dataset.alt;
      img.dataset.description = placeholder.dataset.description;
    }
    return img;
  }

  function showSlide() {
    image = getImage(activeItems[index]);
    if (image) {
      container.appendChild(image);
      description.innerText = image.dataset.description;
    }

    if (activeItems.length > 1) {
      meta.innerText = `${index + 1} von ${activeItems.length}`;

      if (index === 0) {
        prevBtn.classList.add("hidden");
      } else {
        prevBtn.classList.remove("hidden");
      }

      if (index === activeItems.length - 1) {
        nextBtn.classList.add("hidden");
      } else {
        nextBtn.classList.remove("hidden");
      }
    } else {
      meta.innerText = "";
      prevBtn.classList.add("hidden");
      nextBtn.classList.add("hidden");
    }
  }

  function toNextSlide() {
    image.remove();
    index++;
    showSlide();
  }

  function toPrevSlide() {
    image.remove();
    index--;
    showSlide();
  }

  galleryNodes.forEach(item => {
    initLbGallery(item);
  });
  function initLbGallery(galleryNode) {
    const items = galleryNode.querySelectorAll(".lb-gallery__item");
    let touch = null;

    items.forEach((slide, idx) => {
      slide.addEventListener("click", function () {
        backdrop.classList.add("open");
        index = idx;
        activeItems = items;
        showSlide();
      });
    });
  }

  // swipe
  container.addEventListener("touchmove", (e) => {
    if (touch === null) touch = e.touches["0"].clientX;
  });

  container.addEventListener("touchend", (e) => {
    if (!touch) return;
    if (touch > e.changedTouches["0"].clientX) {
      if (index < activeGalleryLength - 1) {
        toNextSlide();
      }
    } else {
      if (index > 0) {
        toPrevSlide();
      }
    }
  });

  // image click
  container.addEventListener("click", function (e) {
    if (e.target.nodeName === "BUTTON") return;
    const half = galleryContainer.offsetWidth / 2;
    if (e.offsetX < half) {
      if (index > 0) {
        toPrevSlide();
      }
    } else {
      if (index < activeGalleryLength - 1) {
        toNextSlide();
      }
    }
  });

  // buttons click
  prevBtn.addEventListener("click", toPrevSlide);

  nextBtn.addEventListener("click", toNextSlide);
}

function cropText(element, count) {
  const text = element.innerText;
  if (!text) return;
  if (text.length < count) return;
  element.innerText = text.slice(0, count) + "...";
}

function loadFrame(frame) {
  frame.src = frame.dataset.src;
}

function initTour(tourNode) {
  const tourTrigger = tourNode.querySelector('.js-tour-trigger');

  function showFrame() {
    tourTrigger.parentNode.classList.add('hidden');
    const tourFrame = tourNode.querySelector('iframe');

    if ('IntersectionObserver' in window) {
      const lazyFrameObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const lazyFrame = entry.target;
            loadFrame(lazyFrame);
            lazyFrameObserver.unobserve(lazyFrame);
          }
        });
      });
      lazyFrameObserver.observe(tourFrame);
    } else {
      loadFrame(tourFrame);
    }
  }

  if (tourNode.dataset.desktop === '0' && !isMobile) {
    showFrame();
  } else {
    tourTrigger.classList.remove('hidden');
  }

  tourTrigger.addEventListener('click', showFrame);
}

function initVesselPositions(frameNode) {
  const frameTrigger = frameNode.querySelector('.js-positions-trigger');

  function showFrame() {
    frameTrigger.parentNode.classList.add('hidden');
    const vpFrame = frameNode.querySelector('iframe');

    if ('IntersectionObserver' in window) {
      const lazyFrameObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const lazyFrame = entry.target;
            loadFrame(lazyFrame);
            lazyFrameObserver.unobserve(lazyFrame);
          }
        });
      });
      lazyFrameObserver.observe(vpFrame);
    } else {
      loadFrame(vpFrame);
    }
  }

  if (frameNode.dataset.mobile === '1' && isMobile) {
    frameTrigger.classList.remove('hidden');
  } else {
    showFrame();
  }

  frameTrigger.addEventListener('click', showFrame);
}


//////////// slider /////////////////

var createElement = function (htmlString) {
  var div = document.createElement('div');
  div.innerHTML = htmlString.trim();
  return div.firstChild;
};

function debounce(func, wait, immediate) {
  if (wait === void 0) { wait = 500; }
  if (immediate === void 0) { immediate = false; }
  var timeout;
  return function () {
    // @ts-ignore
    var context = this, args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate)
        func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow)
      func.apply(context, args);
  };
}

var slider = (function () {
  var sliderClass = 'ks-slider';
  var sliders = document.querySelectorAll("." + sliderClass);
  sliders.forEach(function (slider) {
    var _a, _b, _c, _d, _e, _f;
    var wrapper = slider.querySelector("." + sliderClass + "__wrapper");
    var track = slider.querySelector("." + sliderClass + "__track");
    var slides = Array.from(slider.querySelectorAll("." + sliderClass + "__slide"));
    if (!track || !slides.length)
      return;
    slider.classList.add('initialized');
    track.style.transitionDuration = "0ms";
    var numberOfSlides = slides.length;
    var prevWindowWidth = window.innerWidth;
    var breakpointMd = parseInt((_a = slider.dataset.breakpointMd) !== null && _a !== void 0 ? _a : '1024');
    var breakpointSm = parseInt((_b = slider.dataset.breakpointSm) !== null && _b !== void 0 ? _b : '768');
    var isMobile = window.matchMedia("(max-width: " + (breakpointSm - 1) + "px)").matches;
    var isTablet = window.matchMedia("(min-width: " + breakpointSm + "px) and (max-width: " + (breakpointMd - 1) + "px)").matches;
    var range = isMobile ? 'sm' : isTablet ? 'md' : 'lg';
    var countSm = parseInt((_c = slider.dataset.countSm) !== null && _c !== void 0 ? _c : '1', 10);
    var countMd = parseInt((_d = slider.dataset.countMd) !== null && _d !== void 0 ? _d : '2', 10);
    var countLg = parseInt((_e = slider.dataset.count) !== null && _e !== void 0 ? _e : '4', 10);
    var count = isMobile ? countSm : isTablet ? countMd : countLg;
    var spaceBetween = parseInt((_f = slider.dataset.spaceBetween) !== null && _f !== void 0 ? _f : '20px', 10);
    var slideWidth = (slider.getBoundingClientRect().width - (count - 1) * spaceBetween) / count;
    var currentIndex = 0;
    var loop = slider.dataset.loop === 'true';
    var slidesCloned = [];
    var isTransition = false;
    var translateX = 0;
    var posXInit = -1;
    var posX1 = 0;
    var posX2 = 0;
    var posXFinal = -1;
    var calc = function () {
      isMobile = window.matchMedia("(max-width: " + (breakpointSm - 1) + "px)").matches;
      isTablet = window.matchMedia("(min-width: " + breakpointSm + "px) and (max-width: " + (breakpointMd - 1) + "px)").matches;
      count = isMobile ? countSm : isTablet ? countMd : countLg;
      slideWidth = (slider.getBoundingClientRect().width - (count - 1) * spaceBetween) / count;
      slides.forEach(function (slide) {
        slide.style.width = slideWidth + "px";
        slide.style.marginRight = spaceBetween + "px";
      });
      slidesCloned.forEach(function (slide) {
        slide.style.width = slideWidth + "px";
        slide.style.marginRight = spaceBetween + "px";
      });
      translate();
      if (!loop) {
        controls.style.display = numberOfSlides > count ? 'flex' : 'none';
      }
    };
    var check = function () {
      var _a, _b;
      slider.querySelectorAll("." + sliderClass + "__slide[data-current]")
        .forEach(function (slide) { return slide.removeAttribute('data-current'); });
      slider.querySelectorAll("." + sliderClass + "__slide[data-index=\"" + currentIndex + "\"]")
        .forEach(function (slide) { return slide.setAttribute('data-current', 'true'); });
      (_a = controls.querySelector("." + sliderClass + "__bullet[data-current]")) === null || _a === void 0 ? void 0 : _a.removeAttribute('data-current');
      (_b = controls.querySelector("." + sliderClass + "__bullet[data-index=\"" + currentIndex + "\"]")) === null || _b === void 0 ? void 0 : _b.setAttribute('data-current', 'true');
      if (!loop) {
        prevButton.disabled = currentIndex === 0;
        nextButton.disabled = currentIndex === numberOfSlides - 1;
      }
    };
    var setTranslateX = function (x) {
      translateX = x;
      track.style.transform = "translate3d(" + x + "px, 0, 0)";
    };
    var translate = function (withDuration, duration) {
      if (withDuration === void 0) { withDuration = false; }
      if (duration === void 0) { duration = 250; }
      if (withDuration) {
        track.style.transitionDuration = duration + "ms";
      }
      if (loop) {
        setTranslateX(-(currentIndex + Math.min(numberOfSlides, count)) * (slideWidth + spaceBetween));
      }
      else {
        setTranslateX(-currentIndex * (slideWidth + spaceBetween));
      }
      check();
    };
    var prevSlide = function () {
      if (isTransition)
        return;
      if (!loop) {
        currentIndex = currentIndex > 0 ? currentIndex - 1 : 0;
      }
      else {
        currentIndex = currentIndex > -1 ? currentIndex - 1 : numberOfSlides - 1;
      }
      translate(true);
    };
    var nextSlide = function () {
      if (isTransition)
        return;
      if (!loop) {
        currentIndex = currentIndex < numberOfSlides - 1 ? currentIndex + 1 : numberOfSlides - 1;
      }
      else {
        currentIndex = currentIndex < numberOfSlides ? currentIndex + 1 : 0;
      }
      translate(true);
    };
    var toSlideByIndex = function (index) {
      var time = 250 * Math.sqrt(Math.abs(index - currentIndex));
      currentIndex = index;
      translate(true, time);
    };
    var toSlide = function (evt) {
      if (isTransition)
        return;
      toSlideByIndex(parseInt(evt.target.dataset.index, 10));
    };
    var cloneSlides = function () {
      slidesCloned.forEach(function (slide) { return slide.remove(); });
      slidesCloned = [];
      slides.slice(0, Math.min(count, numberOfSlides)).forEach(function (slide) {
        var clonedSlide = slide.cloneNode(true);
        clonedSlide.classList.add('cloned');
        slidesCloned.push(clonedSlide);
        track.append(clonedSlide);
      });
      slides.slice(Math.max(0, numberOfSlides - count)).reverse().forEach(function (slide) {
        var clonedSlide = slide.cloneNode(true);
        clonedSlide.classList.add('cloned');
        slidesCloned.push(clonedSlide);
        track.prepend(clonedSlide);
      });
    };
    var changeBreakpoint = function () {
      if (loop)
        cloneSlides();
    };
    var swipeStart = function (e) {
      var evt = e instanceof TouchEvent ? e.touches[0] : e;
      posX1 = evt.clientX;
      posXInit = evt.clientX;
      document.addEventListener('touchmove', swipeMove);
      document.addEventListener('mousemove', swipeMove);
      document.addEventListener('touchend', swipeEnd);
      document.addEventListener('mouseup', swipeEnd);
    };
    var swipeMove = function (e) {
      var evt = e instanceof TouchEvent ? e.touches[0] : e;
      posX2 = posX1 - evt.clientX;
      posX1 = evt.clientX;
      setTranslateX(translateX - posX2);
    };
    var swipeEnd = function () {
      posXFinal = posXInit - posX1;
      document.removeEventListener('touchmove', swipeMove);
      document.removeEventListener('mousemove', swipeMove);
      document.removeEventListener('touchend', swipeEnd);
      document.removeEventListener('mouseup', swipeEnd);
      if (posXInit < posX1) {
        prevSlide();
      }
      else if (posXInit > posX1) {
        nextSlide();
      }
    };
    var keyUp = function (e) {
      switch (e.keyCode) {
        case 37:
          prevSlide();
          break;
        case 38:
          toSlideByIndex(0);
          break;
        case 39:
          nextSlide();
          break;
        case 40:
          toSlideByIndex(numberOfSlides - 1);
          break;
      }
    };
    var prevButton = createElement("\n      <button class=\"" + sliderClass + "__arrow prev\" type=\"button\" aria-label=\"Zur vorherigen Folie\">\n        <svg width=\"24\" height=\"24\" fill=\"#002e4f\" viewBox=\"0 0 320 512\">\n          <path\n            d=\"M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z\"/>\n        </svg>\n      </button>\n    ");
    prevButton.addEventListener('click', prevSlide);
    var nextButton = createElement("\n      <button class=\"" + sliderClass + "__arrow next\" type=\"button\" aria-label=\"Zur n\u00E4chsten Folie\">\n        <svg width=\"24\" height=\"24\" fill=\"#002e4f\" viewBox=\"0 0 320 512\">\n          <path\n            d=\"M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z\"/>\n        </svg>\n      </button>\n    ");
    nextButton.addEventListener('click', nextSlide);
    var bullets = createElement("<ul class=\"" + sliderClass + "__bullets\"></ul>");
    for (var i = 0; i < numberOfSlides; i++) {
      var bullet = createElement("\n        <li>\n          <button class=\"" + sliderClass + "__bullet\" type=\"button\" data-index=\"" + i + "\" aria-label=\"Zur Folie " + (i + 1) + "\">\n            " + i + "\n          </button>\n        </li>\n      ");
      bullet.addEventListener('click', toSlide);
      bullets.append(bullet);
    }
    var controls = createElement("<div class=\"" + sliderClass + "__controls\"></div>");
    controls.append(prevButton);
    controls.append(bullets);
    controls.append(nextButton);
    slider.append(controls);
    slides.forEach(function (slide, index) {
      return slide.setAttribute('data-index', index.toString());
    });
    if (loop)
      cloneSlides();
    setTimeout(calc);
    translate();
    wrapper === null || wrapper === void 0 ? void 0 : wrapper.addEventListener('touchstart', swipeStart);
    wrapper === null || wrapper === void 0 ? void 0 : wrapper.addEventListener('mousedown', swipeStart);
    wrapper === null || wrapper === void 0 ? void 0 : wrapper.addEventListener('keyup', keyUp);
    track.addEventListener('transitionstart', function () { return isTransition = true; });
    track.addEventListener('transitionend', function () {
      isTransition = false;
      if (currentIndex >= numberOfSlides) {
        currentIndex = 0;
        translate();
      }
      if (currentIndex <= -1) {
        currentIndex = numberOfSlides - 1;
        translate();
      }
      track.style.transitionDuration = "0ms";
    });
    window.addEventListener('resize', debounce(function () {
      if (window.innerWidth !== prevWindowWidth) {
        prevWindowWidth = window.innerWidth;
        calc();
        var newRange = isMobile ? 'sm' : isTablet ? 'md' : 'lg';
        if (range !== newRange) {
          changeBreakpoint();
          range = newRange;
        }
      }
    }));
  });
});

/////////// end of slider code  ////////////

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return false;
}

window.addEventListener('load', function () {
  const COOKIE_NAME = "dpPopup";
  const popupNode = document.getElementById('dpPopUp');
  const closeBtn = document.querySelector('.js-closePopup');

  if (getCookie(COOKIE_NAME) != 'true' && popupNode) {
    popupNode.classList.add('open');
  }

  if (closeBtn) {
    closeBtn.addEventListener('click', function () {
      if (popupNode) popupNode.classList.remove('open');
      setCookie(COOKIE_NAME, 'true', 3);
    });
  }
});
